import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import SmartText from "../utils/TextHandler";

// Markup
const FourOhFourPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: doc.name,
    description: doc.description,
  };

  return (
    <Layout
      title="404"
      colour="blue"
      image={doc.error_image && doc.error_image}
    >
      <Meta openGraph={openGraph} meta={doc.meta} location="404" />
      <section>
        <div className="container">
          <div className="brand-background-white p-4">
            {doc.error_title && (
              <h2 className="brand-font-uppercase brand-font-bold">
                <SmartText>{doc.error_title}</SmartText>
              </h2>
            )}
            {doc.error_body && <SmartText>{doc.error_body}</SmartText>}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FourOhFourPage;

export const QUERY = graphql`
  query FourOhFourPageQuery {
    index: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
